import Color from 'color';

// export main colour library as well
export { Color };

const HALF = 0.5;

// include core colours in main export
// so that consuming applications have access to the hex colours in JS
export const DEFAULT_COLORS = {
  'grey-1': '#111111',
  'grey-2': '#434143',
  'grey-3': '#606060',
  'grey-4': '#767676',
  'grey-5': '#949494',
  'grey-6': '#d8d8d8',
  'grey-7': '#ececec',
  'grey-8': '#fafafa',
  'system-advisory-background': '#FFE6BD',
  'system-advisory-outline': '#D4A85F',
  'system-advisory-text': '#855200',
  'system-success-background': '#E6F5E4',
  'system-success-outline': '#8EBD84',
  'system-success-text': '#1B6C09',
  'system-warning-background': '#FFEBEB',
  'system-warning-outline': '#EB9494',
  'system-warning-text': '#BD0000',
  'focus-color': '#408CB3',
  'focus-color-light': '#569DC2',
};

/**
 * basic colour blend
 * @param {string|Color} color1
 * @param {string|Color} color2
 * @returns {Color}
 */
export const blend = (color1, color2) => {
  const c1 = Color(color1).object();
  const c2 = Color(color2).object();

  return Color({
    r: HALF * c1.r + HALF * c2.r,
    g: HALF * c1.g + HALF * c2.g,
    b: HALF * c1.b + HALF * c2.b,
  }).alpha(1 - HALF * HALF);
};

/**
 * combine two colours with varying alpha values
 * @param {string|Color} colorTop
 * @param {string|number} alphaTop - between 0 and 1
 * @param {string|Color} colorBottom
 * @returns {Color}
 * @see https://stackoverflow.com/questions/28900598/how-to-combine-two-colors-with-varying-alpha-values
 */
export const layer = (colorTop, alphaTop, colorBottom) => {
  const cTop = Color(colorTop).object();
  const cBottom = Color(colorBottom).object();
  const alphaTopNum = parseFloat(alphaTop);

  return Color({
    r: (1 - alphaTopNum) * cBottom.r + alphaTopNum * cTop.r,
    g: (1 - alphaTopNum) * cBottom.g + alphaTopNum * cTop.g,
    b: (1 - alphaTopNum) * cBottom.b + alphaTopNum * cTop.b,
  }).rgb();
};

/**
 * choose which of two colours provides the greatest contrast on a given background
 * @param {string|Color} backgroundColor
 * @param {string|Color?} darkColor
 * @param {string|Color?} lightColor
 * @returns {string|Color} - based on the original dark or light colours that were provided
 */
export const contrast = (
  backgroundColor,
  darkColor = '#000000',
  lightColor = '#ffffff',
) => {
  const background = Color(backgroundColor);
  const dark = Color(darkColor);
  const light = Color(lightColor);
  const darkContrast = background.contrast(dark);
  const lightContrast = background.contrast(light);
  return darkContrast > lightContrast ? darkColor : lightColor;
};

/**
 * add alpha value to a colour string
 * @param {string|Color} color
 * @param {number} alpha - between 0 and 1
 * @returns {Color}
 */
export const withAlpha = (color, alpha) => {
  return Color(color).alpha(alpha);
};

/**
 * darken a colour by an absolute amount (in the same way as LESS darken does)
 * @param {string|Color} color
 * @param {string|number} amount - between 0 and 100
 * @returns {Color}
 * @example darkenAbsolute('#A2378E', '10%')
 * @example darkenAbsolute('#A2378E', 15)
 */
export const darkenAbsolute = (color, amount = 0) => {
  const hsl = Color(color).hsl();
  hsl.color[2] -= parseFloat(amount);
  if (hsl.color[2] < 0) {
    hsl.color[2] = 0;
  }
  return hsl;
};

/**
 * lighten a colour by an absolute amount (in the same way as LESS lighten does)
 * @param {string|Color} color
 * @param {string|number} amount - between 0 and 100
 * @returns {Color}
 * @example lightenAbsolute('#A2378E', '10%')
 * @example lightenAbsolute('#A2378E', 15)
 */
export const lightenAbsolute = (color, amount = 0) => {
  const hsl = Color(color).hsl();
  hsl.color[2] += parseFloat(amount);
  if (hsl.color[2] > 100) {
    hsl.color[2] = 100;
  }
  return hsl;
};

/**
 * mix two colours together
 * @param {string|Color} color1
 * @param {string|Color} color2
 * @param {number?} weight - the weight of color2, to be mixed into color1
 * @returns {Color}
 */
export const mix = (color1, color2, weight) => {
  return Color(color1).mix(Color(color2), weight);
};
