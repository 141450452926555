import clsx from 'clsx';
import { string } from 'prop-types';
import React from 'react';
import './PageBackground.less';

export default function PageBackground({ className, ...props }) {
  return <div className={clsx('kal-page-background', className)} {...props} />;
}

PageBackground.propTypes = {
  /** Applied directly to the root DOM element */
  className: string,
};
