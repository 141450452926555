import React from 'react';
import { elementType, string, bool } from 'prop-types';
import clsx from 'clsx';
import './VisuallyHidden.less';

const renderVisuallyHidden = (
  { component: Component = 'span', className, focusable, ...props },
  ref,
) => (
  <Component
    {...props}
    ref={ref}
    className={clsx(className, 'kal-visually-hidden', {
      'kal-visually-hidden--focusable': focusable,
    })}
  />
);

const VisuallyHidden = React.forwardRef(renderVisuallyHidden);

VisuallyHidden.propTypes = {
  component: elementType,
  className: string,
  focusable: bool,
};

export default VisuallyHidden;
