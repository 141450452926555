import './ErrorSection.less';
import React from 'react';
import { string } from 'prop-types';
import Icon from '../../assets/svgs/ErrorDisplayIcon.svg.js';

const ErrorSection = ({ mainMessage, secondaryMessage, ...props }) => {
  return (
    <div className='error-section' {...props}>
      <Icon className='error-section__icon' />
      <div className='error-section__main'>{mainMessage}</div>
      <div className='error-section__secondary'>{secondaryMessage}</div>
    </div>
  );
};

ErrorSection.propTypes = {
  mainMessage: string,
  secondaryMessage: string,
};

ErrorSection.defaultProps = {
  mainMessage: '',
  secondaryMessage: '',
};

export default ErrorSection;
