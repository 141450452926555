import { blend, layer, DEFAULT_COLORS } from './themingHelpers';

// reference https://zeroheight.com/6822399ec/p/218128-colour/b/9521f6/t/517659
const PRIMARY_BACKGROUND_TINT_AMOUNT = 0.02;
const DARK_PRIMARY_BACKGROUND_TINT_AMOUNT = 0.3;
const GREY_BACKGROUND_TINT_AMOUNT = 0.04;
const GREY_TINTED_WHITE = layer(
  '#808080',
  GREY_BACKGROUND_TINT_AMOUNT,
  '#ffffff',
);
const GREY_1 = DEFAULT_COLORS['grey-1'];

const TINTED_MASK_ALPHA = 0.5;

export function getTintedMaskColor({ brandPrimaryColor }) {
  return layer(
    brandPrimaryColor,
    PRIMARY_BACKGROUND_TINT_AMOUNT,
    GREY_TINTED_WHITE,
  )
    .alpha(TINTED_MASK_ALPHA)
    .rgb()
    .string();
}

export function getTintedBackgroundColor({ brandPrimaryColor }) {
  return layer(
    brandPrimaryColor,
    PRIMARY_BACKGROUND_TINT_AMOUNT,
    GREY_TINTED_WHITE,
  )
    .rgb()
    .string();
}

export function getDarkTintedBackgroundColor({ brandPrimaryColor }) {
  return layer(
    brandPrimaryColor,
    DARK_PRIMARY_BACKGROUND_TINT_AMOUNT,
    '#000000',
  )
    .alpha(GREY_BACKGROUND_TINT_AMOUNT)
    .rgb()
    .string();
}

export function getDarkTintColor({ brandPrimaryColor }) {
  return blend(brandPrimaryColor, GREY_1).rgb().string();
}

// reference https://zeroheight.com/6822399ec/p/758b21-cards/b/00fe36
const PRIMARY_SHADOW_TINT_AMOUNT = 0.4;
const SHADOW_OPACITY = 0.2;

export function getTintedShadowColor({ brandPrimaryColor }) {
  const baseShadowColor = layer(
    brandPrimaryColor,
    PRIMARY_SHADOW_TINT_AMOUNT,
    '#000',
  );
  return baseShadowColor
    .fade(1 - SHADOW_OPACITY)
    .rgb()
    .string();
}
