import React from 'react';

const ErrorDisplayIcon = (props) => {
  return (
    <svg width={100} height={77} viewBox='0 0 100 77' {...props}>
      <g fill='#606060' fillRule='evenodd'>
        <ellipse cx={6.983} cy={8.649} rx={1.955} ry={1.953} />
        <ellipse cx={14.246} cy={8.649} rx={1.955} ry={1.953} />
        <ellipse cx={21.508} cy={8.649} rx={1.955} ry={1.953} />
        <path fillRule='nonzero' d='M5.587 0h88.826A5.582 5.582 0 01100 5.58v65.84A5.583 5.583 0 0194.413 77H5.587A5.582 5.582 0 010 71.42V5.58A5.583 5.583 0 015.587 0zm0 1.395a4.188 4.188 0 00-4.19 4.185v65.84a4.188 4.188 0 004.19 4.185h88.826a4.188 4.188 0 004.19-4.185V5.58a4.188 4.188 0 00-4.19-4.185H5.587z' />
        <path fillRule='nonzero' d='M.978 17.158h98.603v-1.395H.978z' />
        <g fillRule='nonzero'>
          <g transform='translate(42.794 36.603)'>
            <ellipse cx={1.397} cy={1.395} rx={1.117} ry={1.116} transform='translate(5.81 19.752)' />
            <path d='M0 5.803C0 2.62 3.22 0 7.206 0c3.987 0 7.206 2.62 7.207 5.801 0 1.177-.432 2.31-1.256 3.277-.256.294-.567.599-.96.947a37.87 37.87 0 01-1.062.896c-2.35 1.94-3.187 2.863-3.187 4.088v2.046c0 .376-.329.688-.742.688s-.742-.312-.742-.688V15.01c0-1.562.954-2.83 3.118-4.645.157-.132.284-.237.567-.47 1.044-.862 1.464-1.233 1.849-1.675.605-.712.93-1.551.93-2.416 0-2.464-2.583-4.427-5.722-4.427-3.138 0-5.722 1.963-5.722 4.427 0 .376-.329.688-.742.688S0 6.18 0 5.803z' />
          </g>
          <path d='M35.185 31.043l4.74 4.734c.214.214.585.205.83-.04.244-.244.253-.614.039-.828l-4.74-4.734c-.215-.214-.585-.205-.83.04-.245.244-.254.614-.04.828zm29.631 0l-4.74 4.734c-.214.214-.585.205-.83-.04-.244-.244-.253-.614-.04-.828l4.741-4.734c.215-.214.585-.205.83.04.245.244.254.614.04.828zM40.794 57.268l-4.74 4.734c-.215.214-.585.205-.83-.04-.245-.244-.254-.614-.04-.828l4.74-4.735c.215-.214.586-.205.83.04.245.244.254.614.04.828zM30.727 46.041h6.704c.303 0 .558-.268.558-.614s-.255-.614-.558-.614h-6.704c-.303 0-.559.268-.559.614s.256.614.559.614zm18.715-21.769v6.695c0 .303.268.558.614.558s.615-.255.615-.558v-6.695c0-.303-.269-.558-.615-.558s-.614.255-.614.558zM62.57 46.041h6.704c.303 0 .559-.268.559-.614s-.256-.614-.559-.614H62.57c-.303 0-.558.268-.558.614s.255.614.558.614zm-3.363 11.227l4.74 4.734c.215.214.585.205.83-.04.245-.244.254-.614.04-.828l-4.741-4.735c-.214-.214-.585-.205-.83.04-.244.244-.253.614-.04.828z' />
          <path d='M64.943 30.835l-4.74 4.735c-.214.214-.585.205-.83-.04-.244-.244-.253-.614-.039-.828l4.74-4.735c.215-.214.585-.205.83.04.245.244.254.614.04.828z' />
        </g>
      </g>
    </svg>
  );
};

export default ErrorDisplayIcon;
