import Color from 'color';
import {
  calculateHeroGradient,
  calculateInitialHeroGradientColor,
} from './calculateHeroGradient';
import { contrast, DEFAULT_COLORS, layer, withAlpha } from './themingHelpers';
import {
  getDarkTintColor,
  getDarkTintedBackgroundColor,
  getTintedBackgroundColor,
  getTintedMaskColor,
  getTintedShadowColor,
} from './tintedColors';
import { CATEGORY_COLORS, EXTENDED_COLORS } from './extendedColors';

const BUTTON_HOVER_ALPHA = 0.2;
const BUTTON_DISABLED_ALPHA = 0.1;
const COLOR_LIGHTEN_AMOUNT = 0.1;
const COLOR_DARKEN_AMOUNT = 0.1;
const OUTLINE_OPACITY = 0.8;
const STRONG_OUTLINE_OPACITY = 0.5;

const colorMap = (colorsObject) => {
  const colors = {};
  Object.entries(colorsObject).map(([key, value]) => {
    colors[`${key}-background`] = value;
    colors[`${key}-background-low-opacity`] = layer(
      value,
      BUTTON_DISABLED_ALPHA,
      '#FFF',
    )
      .rgb()
      .string();
  });
  return colors;
};

// https://zeroheight.com/6822399ec/p/218128-colour/b/9521f6
export default {
  'brand-primary-color': (cssVariables) => cssVariables.brandPrimaryColor,
  'brand-primary-color-light': (cssVariables) =>
    Color(cssVariables.brandPrimaryColor).lighten(COLOR_LIGHTEN_AMOUNT).hex(),
  'brand-primary-color-dark': (cssVariables) =>
    Color(cssVariables.brandPrimaryColor).darken(COLOR_DARKEN_AMOUNT).hex(),
  'brand-primary-contrast-text-color': (cssVariables) =>
    contrast(
      cssVariables.brandPrimaryColor,
      cssVariables.defaultTextColor,
      cssVariables.whiteColor,
    ),
  'brand-primary-hover-color': (cssVariables) =>
    layer(
      cssVariables.brandPrimaryColor,
      BUTTON_HOVER_ALPHA,
      cssVariables.whiteColor,
    )
      .rgb()
      .string(),
  'brand-primary-low-opacity-color': (cssVariables) =>
    layer(
      cssVariables.brandPrimaryColor,
      BUTTON_DISABLED_ALPHA,
      cssVariables.whiteColor,
    )
      .rgb()
      .string(),
  'brand-primary-outline': (cssVariables) =>
    Color(cssVariables.brandPrimaryColor).fade(OUTLINE_OPACITY).rgb().string(),
  'brand-primary-strong-outline': (cssVariables) =>
    Color(cssVariables.brandPrimaryColor)
      .fade(STRONG_OUTLINE_OPACITY)
      .rgb()
      .string(),
  'brand-primary-tinted-shadow-color': getTintedShadowColor,
  'brand-primary-tinted-background-color': getTintedBackgroundColor,
  'brand-primary-dark-tinted-background-color': getDarkTintedBackgroundColor,
  'brand-primary-dark-tint-color': getDarkTintColor,
  'brand-primary-tinted-mask': getTintedMaskColor,
  'default-text-color': (cssVariables) => cssVariables.defaultTextColor,
  'white-color': (cssVariables) => cssVariables.whiteColor,
  'initial-hero-gradient-color': calculateInitialHeroGradientColor,
  'hero-background-gradient': calculateHeroGradient,
  'grey-1': DEFAULT_COLORS['grey-1'],
  'grey-2': DEFAULT_COLORS['grey-2'],
  'grey-3': DEFAULT_COLORS['grey-3'],
  'grey-4': DEFAULT_COLORS['grey-4'],
  'grey-5': DEFAULT_COLORS['grey-5'],
  'grey-6': DEFAULT_COLORS['grey-6'],
  'grey-7': DEFAULT_COLORS['grey-7'],
  'grey-8': DEFAULT_COLORS['grey-8'],
  'system-advisory-background': DEFAULT_COLORS['system-advisory-background'],
  'system-advisory-outline': DEFAULT_COLORS['system-advisory-outline'],
  'system-advisory-text': DEFAULT_COLORS['system-advisory-text'],
  'system-success-background': DEFAULT_COLORS['system-success-background'],
  'system-success-outline': DEFAULT_COLORS['system-success-outline'],
  'system-success-text': DEFAULT_COLORS['system-success-text'],
  'system-warning-background': DEFAULT_COLORS['system-warning-background'],
  'system-warning-outline': DEFAULT_COLORS['system-warning-outline'],
  'system-warning-text': DEFAULT_COLORS['system-warning-text'],
  'system-warning-hover': withAlpha(
    DEFAULT_COLORS['system-warning-text'],
    BUTTON_HOVER_ALPHA,
  ).string(),
  'focus-color': DEFAULT_COLORS['focus-color'],
  'focus-color-light': DEFAULT_COLORS['focus-color-light'],
  ...colorMap({ ...CATEGORY_COLORS, ...EXTENDED_COLORS }),
};
