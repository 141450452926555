import { func, node, oneOfType } from 'prop-types';
import React, { Component } from 'react';
import ErrorSection from './ErrorSection';

// based on React docs example https://reactjs.org/docs/react-component.html#componentdidcatch
export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    this.props.onCatchError(error, info);
  }

  render() {
    const { error } = this.state;
    const { children, mainMessage, fallback, ...props } = this.props;

    if (!error) {
      return children;
    }

    if (typeof fallback === 'function') {
      return fallback(error, this.props);
    }

    if (React.isValidElement(fallback)) {
      return fallback;
    }

    return (
      <ErrorSection
        mainMessage={mainMessage}
        secondaryMessage={this.state.error.message}
        {...props}
      />
    );
  }
}

ErrorHandler.defaultProps = {
  mainMessage: 'Something went wrong!',
  onCatchError: () => undefined, // do nothing
};

ErrorHandler.propTypes = {
  children: node,
  mainMessage: node,
  onCatchError: func,
  fallback: oneOfType([node, func]),
};
