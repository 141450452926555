import Color from 'color';
import { layer } from './themingHelpers';

// reference https://zeroheight.com/6822399ec/p/13128c-page-header/t/9871fb
const HERO_GRADIENT_HUE_SHIFT = 32;
const HERO_GRADIENT_INITIAL_OPACITY = 0.23;
const HERO_GRADIENT_FINAL_OPACITY = 0.29;

export function calculateInitialHeroGradientColor({ brandPrimaryColor }) {
  return layer(
    Color(brandPrimaryColor).rotate(HERO_GRADIENT_HUE_SHIFT).rgb(),
    HERO_GRADIENT_INITIAL_OPACITY,
    brandPrimaryColor,
  );
}

export function calculateHeroGradient({ brandPrimaryColor }) {
  const gradientStartColor = calculateInitialHeroGradientColor({
    brandPrimaryColor,
  });

  const gradientEndColor = layer(
    Color('#000000').rgb(),
    HERO_GRADIENT_FINAL_OPACITY,
    brandPrimaryColor,
  );
  return `linear-gradient(104deg, ${gradientStartColor} 42%, ${gradientEndColor} 110%)`;
}
