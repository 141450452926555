//https://zeroheight.com/6822399ec/p/218128-colour/b/06ff86

export const CATEGORY_COLORS = {
  'sapling-blue': '#3F1DCB',
  purple: '#A91DCC',
  berry: '#CC1D86',
  'burnt-orange': '#CC1D1D',
  turmeric: '#CC831D',
  lime: '#ACCC1D',
  'harlequin-green': '#43CC1D',
  malachite: '#1DCC60',
  cyan: '#1DCCC9',
  denim: '#1D66CC',
};
export const EXTENDED_COLORS = {
  'sapling-blue-2': '#5A1DCC',
  'sapling-blue-3': '#741DCC',
  'sapling-blue-4': '#8F1DCC',
  'purple-2': '#C31DCC',
  'purple-3': '#CC1DBB',
  'purple-4': '#CC1DA0',
  'berry-2': '#CC1D6C',
  'berry-3': '#CC1D52',
  'berry-4': '#CC1D37',
  'burnt-orange-2': '#CC341D',
  'burnt-orange-3': '#CC4E1D',
  'burnt-orange-4': '#CC691D',
  'turmeric-2': '#CC9D1D',
  'turmeric-3': '#CCB81D',
  'turmeric-4': '#C6CC1D',
  'lime-2': '#92CC1D',
  'lime-3': '#77CC1D',
  'lime-4': '#5DCC1D',
  'harlequin-green-2': '#29CC1D',
  'harlequin-green-3': '#1DCC2C',
  'harlequin-green-4': '#1DCC46',
  'malachite-2': '#1DCC7A',
  'malachite-3': '#1DCC95',
  'malachite-4': '#1DCCAF',
  'cyan-2': '#1DB5CC',
  'cyan-3': '#1D9ACC',
  'cyan-4': '#1D80CC',
  'denim-2': '#1D4FCC',
  'denim-3': '#1D37CC',
  'denim-4': '#1D1DCC',
};
