import { string } from 'prop-types';
import React, { useMemo } from 'react';
import { DEFAULT_COLORS } from './themingHelpers';
import VARIABLE_DEFINITIONS from './themeVariableDefinitions';

function getThemeCss(cssVariables, scope = ':root', furtherVars = '') {
  const variableDefinitions = Object.entries(VARIABLE_DEFINITIONS).map(
    ([variableName, variableDefinition]) => {
      if (typeof variableDefinition !== 'function') {
        return `--${variableName}: ${variableDefinition};`;
      }
      return `--${variableName}: ${variableDefinition(cssVariables)};`;
    },
  );

  return [`${scope} {`, ...variableDefinitions, furtherVars, `}`]
    .filter(Boolean)
    .join('\n');
}

const FALLBACK_CSS_VARIABLES = {
  brandPrimaryColor: '#3F1DCB',
  defaultTextColor: DEFAULT_COLORS['grey-1'],
  whiteColor: '#ffffff',
};

/**
 * generate stylesheet contents for theming css variables
 * @param {object} cssVariables
 * @param {string?} scope
 * @param {string?} furtherVars
 * @returns {string}
 */
export const getKallidusThemeCss = (cssVariables, scope, furtherVars) => {
  try {
    return getThemeCss(cssVariables, scope, furtherVars);
  } catch (error) {
    // each UI may not validate hex colors entered by the user, so could be invalid.
    // So we fallback to a known good set of variables if so.
    console.error(error);
    return getThemeCss(FALLBACK_CSS_VARIABLES, scope, furtherVars);
  }
};

const KallidusThemeVariables = ({
  brandPrimaryColor,
  defaultTextColor = FALLBACK_CSS_VARIABLES.defaultTextColor,
  whiteColor = FALLBACK_CSS_VARIABLES.whiteColor,
  scope = ':root',
  ...props
}) => {
  const themeCss = useMemo(() => {
    const brandColors = { brandPrimaryColor, defaultTextColor, whiteColor };
    return getKallidusThemeCss(brandColors, scope);
  }, [brandPrimaryColor, defaultTextColor, whiteColor, scope]);
  return <style {...props}>{themeCss}</style>;
};

KallidusThemeVariables.propTypes = {
  // The primary brand color, used to tint buttons, backgrounds, hero units, etc. Must be a valid CSS color string (see https://www.npmjs.com/package/color-string for valid formats)
  brandPrimaryColor: string.isRequired,
  // Default text color for use on light backgrounds
  defaultTextColor: string,
  // Basic white color
  whiteColor: string,
  // A valid CSS selector. Variable changes will only be applied to this scope
  scope: string,
};

export default KallidusThemeVariables;
